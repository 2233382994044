import { getLayoutData, mergeMeta } from '@app/utils/remix/client-meta';
import { leadOrDescription } from '@play-now/core/utils/string-helper';
import { toSeoString } from '@play-now/core/utils/to-seo-string';
import { getRemixMeta } from '@play-now/video/components/Meta/PlayMetaData';
import { useInitialData } from '@play-now/video/config/VideoAppContext';
import { TopicPage } from '@play-now/video/pages/Topic/TopicPage';
import { MetaFunction } from '@remix-run/node';

import { PlayLayout } from './PlayLayout';

export const meta: MetaFunction = ({ matches, params }) => {
  const layoutData = getLayoutData(matches);
  let metaData;
  if (layoutData && params.topicName) {
    const topic = layoutData.initialData.topics?.find((t) => toSeoString(t.title) === params.topicName);
    if (topic) {
      metaData = getRemixMeta({ config: layoutData.config, title: topic.title, description: leadOrDescription(topic) });
    }
  }
  return mergeMeta({ data: { meta: metaData }, matches });
};

const TopicRoute = () => {
  const initialData = useInitialData();
  return (
    <PlayLayout pageId='topic'>
      <TopicPage initialData={initialData} />
    </PlayLayout>
  );
};

export default TopicRoute;
