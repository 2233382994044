import { useQuery } from '@tanstack/react-query';
import { loadPacTopicPage } from '../../api/pac/load-pac-topic-page';
import { queryKeys } from '../../config/use-query';
import { useVideoConfig } from '../../config/VideoAppContext';
import { useAxios } from '../../hooks/use-axios';
export const useTopicSections = (initialData, topic) => {
  const axios = useAxios();
  const {
    usePreview,
    previewDate
  } = useVideoConfig();
  const initialTopicPage = initialData.pacPageConfigs?.topicPages?.[topic?.urn ?? ''];
  const shouldLoadTopicSections = getSections(initialTopicPage).length < 1;
  const {
    data: topicPage = initialTopicPage,
    isLoading
  } = useQuery({
    queryKey: [queryKeys.PacTopicPage(topic?.urn ?? ''), {
      usePreview,
      previewDate
    }, axios],
    queryFn: () => loadPacTopicPage(axios, topic?.urn ?? '', usePreview, previewDate),
    enabled: shouldLoadTopicSections
  });
  return {
    topicSections: getSections(topicPage),
    isLoading
  };
};
const getSections = page => page?.sections ?? [];