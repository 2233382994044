import { createRequestUrl } from '../../../../play-core/utils/request-url-helper';
export const PacTopicPageSectionsApiPath = '/topic-page';
export const loadPacTopicPage = async (axios, topicUrn, preview, previewDate) => {
  const requestUrl = createRequestUrl(PacTopicPageSectionsApiPath, {
    topicUrn,
    preview,
    previewDate
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};